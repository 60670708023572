import ModalOptions from "./@types/modal.options";

export class ModalHelper {

  private static modalContainer: { modalStack: ModalOptions[], close(id?: string): Promise<void>, popModal(id?: string): void } | null = null;

  public static push(options: ModalOptions) {
    ModalHelper.modalContainer?.modalStack.push(options);
  }

  public static setContainer(container: { modalStack: ModalOptions[], close(id: string): Promise<void>, popModal(id: string): void }) {
    ModalHelper.modalContainer = container;
  }

  public static close(id?: string) {
    ModalHelper.modalContainer?.close(id);
  }

  public static pop(id?: string) {
    ModalHelper.modalContainer?.popModal(id);
  }
}