import Vue from 'vue'
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {

  }
})
export default class Slider extends Vue {

  @Prop({ default: 0 })
  min: number
  
  @Prop({ default: 100 })
  max: number

  @Prop({ default: 1 })
  step: number  

  @Prop()
  value: number

  get mediumValue(){
    return (this.max + this.min) / 2
  }

  selected: number = 0
  mounted() {
    this.selected = this.value ?? this.min
  }

  @Watch("value")
  changeInistial() {
    this.selected = this.value
  }
  
  @Watch("selected")
  change() {
    this.$emit("change", this.selected)
  }
}
