import * as popupUtils from "@arcgis/core/support/popupUtils.js";

export function GetExposurePointPopup() {
  return GetPopupTemplate(GetExposurePointFields(),"Punto di presa");
}

export function GetCampatePopup() {
  return GetPopupTemplate(GetCampateFields(),"Campata");
}

export function GetSostegniPopup() {
  return GetPopupTemplate(GetSostegniFields(),"Sostegno");
}

export function GetLineePopup() {
  return GetPopupTemplate(GetLineeFields(),"Linea");
}

export function GetPopupTemplate(fields: __esri.Field[],title) {
  const config = {
    displayField: "*",
    fields: fields,
    title: title
  };

  const templateOptions = {
    // ignoreFieldTypes: ["date"],
    // visibleFieldNames: new Set(["NAME", "ALAND"])
  };

  return popupUtils.createPopupTemplate(config, templateOptions);
}

export function GetExposurePointFields() {
  return [
    { name: "OBJECTID      ", alias: "        OBJECTID " },
{ name: "name          ", alias: "    name " },
{ name: "image         ", alias: "     image " },
{ name: "camheading    ", alias: "          camheading " },
{ name: "campitch      ", alias: "        campitch " },
{ name: "camroll       ", alias: "       camroll " },
{ name: "hfov          ", alias: "    hfov " },
{ name: "vfov          ", alias: "    vfov " },
{ name: "avghtag       ", alias: "       avghtag " },
{ name: "fardist       ", alias: "       fardist " },
{ name: "neardist      ", alias: "        neardist " },
{ name: "oitype        ", alias: "      oitype " },
{ name: "sortorder     ", alias: "         sortorder " },
{ name: "camoffset     ", alias: "         camoffset " },
{ name: "accuracy      ", alias: "        accuracy " },
{ name: "imgpyramids   ", alias: "          imgpyramids" },
{ name: "depthimg      ", alias: "        depthimg " },
{ name: "camori        ", alias: "      camori " },
{ name: "imgrot        ", alias: "      imgrot " }
  ] as __esri.Field[];
}

export function GetSostegniFields() {
  return [
    { name: "OBJECTID        ", alias: "              OBJECTID " },
    { name: "ID_SOSTEGN      ", alias: "            ID_SOSTEGN " },
    { name: "FLTYP           ", alias: "       FLTYP " },
    { name: "STRNO           ", alias: "       STRNO " },
    { name: "TPLNR           ", alias: "       TPLNR " },
    { name: "PLTXT           ", alias: "       PLTXT " },
    { name: "SWERK           ", alias: "       SWERK " },
    { name: "WERGW           ", alias: "       WERGW " },
    { name: "GEWRK           ", alias: "       GEWRK " },
    { name: "IWERK           ", alias: "       IWERK " },
    { name: "INGRP           ", alias: "       INGRP " },
    { name: "POSNR           ", alias: "       POSNR " },
    { name: "ID_CAMPATA      ", alias: "            ID_CAMPATA " },
    { name: "TPLNR_SUP       ", alias: "           TPLNR_SUP " },
    { name: "STRNO_SUP       ", alias: "           STRNO_SUP " },
    { name: "GIS_NORD        ", alias: "          GIS_NORD " },
    { name: "GIS_EST         ", alias: "         GIS_EST " },
    { name: "GIS_FUSO        ", alias: "          GIS_FUSO " },
    { name: "GIS_VALID       ", alias: "           GIS_VALID " },
    { name: "LDR_NORD        ", alias: "          LDR_NORD " },
    { name: "LDR_EST         ", alias: "         LDR_EST " },
    { name: "LDR_FUSO        ", alias: "          LDR_FUSO " },
    { name: "LDR_VALID       ", alias: "           LDR_VALID " },
    { name: "GBS_NORD        ", alias: "          GBS_NORD " },
    { name: "GBS_EST         ", alias: "         GBS_EST " },
    { name: "GBS_VALID       ", alias: "           GBS_VALID " },
    { name: "WGS_NORD        ", alias: "          WGS_NORD " },
    { name: "WGS_EST         ", alias: "         WGS_EST " },
    { name: "WGS_VALID       ", alias: "           WGS_VALID " },
    { name: "LDR_QUOTA       ", alias: "           LDR_QUOTA " },
    { name: "GBS_QUOTA       ", alias: "           GBS_QUOTA " },
    { name: "WGS_QUOTA       ", alias: "           WGS_QUOTA " },
    { name: "UNK_QUOTA       ", alias: "           UNK_QUOTA " },
    { name: "GIS_ANGOLO      ", alias: "            GIS_ANGOLO " },
    { name: "GIS_ANGO_1      ", alias: "            GIS_ANGO_1 " },
    { name: "LDR_ANGOLO      ", alias: "            LDR_ANGOLO " },
    { name: "LDR_ANGO_1      ", alias: "            LDR_ANGO_1 " },
    { name: "UNK_ANGOLO      ", alias: "            UNK_ANGOLO " },
    { name: "UNK_ANGO_1      ", alias: "            UNK_ANGO_1 " },
    { name: "COORD_ID        ", alias: "          COORD_ID " },
    { name: "COORD_X         ", alias: "         COORD_X " },
    { name: "COORD_Y         ", alias: "        COORD_Y" },
    { name: "COORD_Z         ", alias: "         COORD_Z " },
    { name: "COORD_SRID      ", alias: "            COORD_SRID " },
    { name: "ANGOLO          ", alias: "        ANGOLO " },
    { name: "TRIF            ", alias: "      TRIF " },
    { name: "TAGG            ", alias: "      TAGG ", },
    { name: "TAGG_ATT        ", alias: "          TAGG_ATT " },
    { name: "TAGG_INT        ", alias: "          TAGG_INT " },
    { name: "VERS            ", alias: "      VERS " },
    { name: "STATO           ", alias: "       STATO " }
  ] as __esri.Field[];
}

export function GetCampateFields() {
  return [
    { name: "OBJECTID       ", alias: "             OBJECTID " },
    { name: "ID_CAMPATA     ", alias: "              ID_CAMPATA" },
    { name: "FLTYP          ", alias: "          FLTYP " },
    { name: "TPLNR          ", alias: "          TPLNR " },
    { name: "STRNO          ", alias: "          STRNO " },
    { name: "PLTXT          ", alias: "          PLTXT " },
    { name: "SWERK          ", alias: "          SWERK " },
    { name: "WERGW          ", alias: "          WERGW " },
    { name: "GEWRK          ", alias: "          GEWRK " },
    { name: "IWERK          ", alias: "          IWERK " },
    { name: "INGRP          ", alias: "          INGRP " },
    { name: "POSNR          ", alias: "          POSNR " },
    { name: "ID_LINEA_S     ", alias: "               ID_LINEA_S" },
    { name: "TPLNR_SUP      ", alias: "              TPLNR_SUP " },
    { name: "STRNO_SUP      ", alias: "              STRNO_SUP " },
    { name: "ID_SOSTEGN     ", alias: "               ID_SOSTEGN" },
    { name: "TPLNR_INI      ", alias: "              TPLNR_INI " },
    { name: "STRNO_INI      ", alias: "              STRNO_INI " },
    { name: "ID_SOSTE_1     ", alias: "               ID_SOSTE_1" },
    { name: "TPLNR_FIN      ", alias: "              TPLNR_FIN " },
    { name: "STRNO_FIN      ", alias: "              STRNO_FIN " },
    { name: "ID_CAMPA_1     ", alias: "               ID_CAMPA_1" },
    { name: "TPLNR_RIF      ", alias: "              TPLNR_RIF " },
    { name: "STRNO_RIF      ", alias: "              STRNO_RIF " },
    { name: "ID_CAMPA_2     ", alias: "               ID_CAMPA_2" },
    { name: "TPLNR_ASS      ", alias: "              TPLNR_ASS " },
    { name: "STRNO_ASS      ", alias: "              STRNO_ASS " },
    { name: "TPLNR_ISP      ", alias: "              TPLNR_ISP " },
    { name: "STRNO_ISP      ", alias: "              STRNO_ISP " },
    { name: "ID_ELEMENT     ", alias: "               ID_ELEMENT" },
    { name: "TIPO_IND       ", alias: "             TIPO_IND " },
    { name: "TPLNR_IND      ", alias: "              TPLNR_IND " },
    { name: "STRNO_IND      ", alias: "              STRNO_IND " },
    { name: "ID_TIPO_CA     ", alias: "               ID_TIPO_CA" },
    { name: "LUNGHEZZA1     ", alias: "               LUNGHEZZA1" },
    { name: "COORD_ID_I     ", alias: "               COORD_ID_I" },
    { name: "COORD_ID_F     ", alias: "               COORD_ID_F" },
    { name: "COORD_ID_M     ", alias: "               COORD_ID_M" },
    { name: "COORD_X_IN     ", alias: "               COORD_X_IN" },
    { name: "COORD_Y_IN     ", alias: "               COORD_Y_IN" },
    { name: "COORD_Z_IN     ", alias: "               COORD_Z_IN" },
    { name: "COORD_X_FI     ", alias: "               COORD_X_FI" },
    { name: "COORD_Y_FI     ", alias: "               COORD_Y_FI" },
    { name: "COORD_Z_FI     ", alias: "               COORD_Z_FI" },
    { name: "COORD_SRID     ", alias: "               COORD_SRID" },
    { name: "TRIF           ", alias: "         TRIF " },
    { name: "TAGG           ", alias: "         TAGG " },
    { name: "TAGG_ATT       ", alias: "             TAGG_ATT " },
    { name: "TAGG_INT       ", alias: "             TAGG_INT " },
    { name: "VERS           ", alias: "         VERS " },
    { name: "STATO          ", alias: "          STATO " }
  ] as __esri.Field[];
}

export function GetLineeFields() {
  return [
    { name: "OBJECTID      ", alias: "      OBJECTID " },
    { name: "ID_CAMPATA    ", alias: "       ID_CAMPATA" },
    { name: "FLTYP         ", alias: "   FLTYP " },
    { name: "TPLNR         ", alias: "   TPLNR " },
    { name: "STRNO         ", alias: "   STRNO " },
    { name: "PLTXT         ", alias: "   PLTXT " },
    { name: "SWERK         ", alias: "   SWERK " },
    { name: "WERGW         ", alias: "   WERGW " },
    { name: "GEWRK         ", alias: "   GEWRK " },
    { name: "IWERK         ", alias: "   IWERK " },
    { name: "INGRP         ", alias: "   INGRP " },
    { name: "POSNR         ", alias: "   POSNR " },
    { name: "ID_LINEA_S    ", alias: "       ID_LINEA_S" },
    { name: "TPLNR_SUP     ", alias: "       TPLNR_SUP " },
    { name: "STRNO_SUP     ", alias: "       STRNO_SUP " },
    { name: "ID_SOSTEGN    ", alias: "       ID_SOSTEGN" },
    { name: "TPLNR_INI     ", alias: "       TPLNR_INI " },
    { name: "STRNO_INI     ", alias: "       STRNO_INI " },
    { name: "ID_SOSTE_1    ", alias: "       ID_SOSTE_1" },
    { name: "TPLNR_FIN     ", alias: "       TPLNR_FIN " },
    { name: "STRNO_FIN     ", alias: "       STRNO_FIN " },
    { name: "ID_CAMPA_1    ", alias: "       ID_CAMPA_1" },
    { name: "TPLNR_RIF     ", alias: "       TPLNR_RIF " },
    { name: "STRNO_RIF     ", alias: "       STRNO_RIF " },
    { name: "ID_CAMPA_2    ", alias: "       ID_CAMPA_2" },
    { name: "TPLNR_ASS     ", alias: "       TPLNR_ASS " },
    { name: "STRNO_ASS     ", alias: "       STRNO_ASS " },
    { name: "ID_TRONCO_    ", alias: "       ID_TRONCO_" },
    { name: "TPLNR_ISP     ", alias: "       TPLNR_ISP " },
    { name: "STRNO_ISP     ", alias: "       STRNO_ISP " },
    { name: "ID_ELEMENT    ", alias: "       ID_ELEMENT" },
    { name: "TIPO_IND      ", alias: "      TIPO_IND " },
    { name: "TPLNR_IND     ", alias: "      TPLNR_IND" },
    { name: "STRNO_IND     ", alias: "      STRNO_IND" },
    { name: "ID_TIPO_CA    ", alias: "        ID_TIPO_CA " },
    { name: "LUNGHEZZA_    ", alias: "        LUNGHEZZA_ " },
    { name: "LUNGHEZZA1    ", alias: "        LUNGHEZZA1 " },
    { name: "COORD_ID_I    ", alias: "        COORD_ID_I " },
    { name: "COORD_ID_F    ", alias: "        COORD_ID_F " },
    { name: "COORD_ID_M    ", alias: "        COORD_ID_M " },
    { name: "COORD_X_IN    ", alias: "        COORD_X_IN " },
    { name: "COORD_Y_IN    ", alias: "        COORD_Y_IN " },
    { name: "COORD_Z_IN    ", alias: "        COORD_Z_IN " },
    { name: "COORD_X_FI    ", alias: "        COORD_X_FI " },
    { name: "COORD_Y_FI    ", alias: "        COORD_Y_FI " },
    { name: "COORD_Z_FI    ", alias: "        COORD_Z_FI " },
    { name: "COORD_SRID    ", alias: "        COORD_SRID " },
    { name: "TRIF          ", alias: " TRIF" },
    { name: "TAGG          ", alias: " TAGG" },
    { name: "TAGG_ATT      ", alias: "      TAGG_ATT " },
    { name: "TAGG_INT      ", alias: "      TAGG_INT " },
    { name: "VERS          ", alias: "  VERS " },
    { name: "STATO         ", alias: "   STATO " }
  ] as __esri.Field[];
}