import Vue from "vue";
import Vuex from "vuex";

import { CreateTypedStoreProxy } from "vuex-typed-store";

import { ImagesStore, ImagesStoreModel, ImagesStoreActions, ImageStoreGetters } from "@/store/stores/image.store";
import { SearchStore, SearchStoreModel, SearchStoreActions, SearchStoreGetters } from "@/store/stores/search.store";
import { DomainStore, DomainStoreModel, DomainStoreActions, DomainStoreGetters } from "@/store/stores/domain.store";
import { TerritorialDistrictStore, TerritorialDistrictStoreModel, TerritorialDistrictStoreActions, TerritorialDistrictStoreGetters } from "./stores/territorialDistrict.store";
export interface StoreModel {
  images: ImagesStoreModel,
  search: SearchStoreModel,
  domain: DomainStoreModel,
  territorialDistrict: TerritorialDistrictStoreModel
}

export interface StoreActions {
  images: ImagesStoreActions,
  search: SearchStoreActions,
  domain: DomainStoreActions,
  territorialDistrict: TerritorialDistrictStoreActions
}

export interface StoreGetters {
  images: ImageStoreGetters,
  search: SearchStoreGetters,
  domain: DomainStoreGetters,
  territorialDistrict: TerritorialDistrictStoreGetters
}

Vue.use(Vuex)

export const vuestore = new Vuex.Store({
  strict: false,
  state: {
  },
  modules: {
    [ImagesStore.PREFIX]: ImagesStore,
    [SearchStore.PREFIX]: SearchStore,
    [DomainStore.PREFIX]: DomainStore,
    [TerritorialDistrictStore.PREFIX]: TerritorialDistrictStore
  } as any,
  actions: {

  },
  mutations: {

  },
  getters: {
  },
})

export default vuestore;
export const store = CreateTypedStoreProxy<StoreModel, StoreActions, StoreGetters>(vuestore);
