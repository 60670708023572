import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import EsriMap from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import esriConfig from "@arcgis/core/config";
import settings from "@/settings";
import Search from "@arcgis/core/widgets/Search";
import * as geometryEngineAsync from "@arcgis/core/geometry/geometryEngineAsync.js";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import LayerList from "@arcgis/core/widgets/LayerList";
import Expand from "@arcgis/core/widgets/Expand";
import { metadataServices } from "@/services/metadata.services";
import * as popupUtils from "@arcgis/core/support/popupUtils.js";
import { GetExposurePointPopup, GetCampatePopup, GetSostegniPopup, GetLineePopup } from "@/utils/layerPopupTemplate";


@Component({ components: {}, })
export default class MapSearch extends Vue {

    @Prop({ default: 4 })
    zoom: number;

    @Prop({ default: () => [12, 42] })
    center: number[];

    @Prop({ default: true })
    showIOCTool: boolean;

    @Prop()
    toRefresh: boolean;

    map: __esri.Map = null;
    mapView: __esri.MapView = null;
    activeView: __esri.MapView | __esri.SceneView = null;

    exposurePointLayerId: string = "exposure-points-layer-id";
    exposurePointLayer: __esri.FeatureLayer = null;
    sostegniLayerId: string = "sostegni-layer-id";
    campateLayer: __esri.FeatureLayer = null;
    campateLayerId: string = "campate-layer-id";
    sostegniLayer: __esri.FeatureLayer = null;
    linesLayerId: string = "lines-layer-id";
    linesLayer: __esri.FeatureLayer = null;
    polygonGraphicsLayer: __esri.GraphicsLayer = null;
    sketchViewModel: __esri.SketchViewModel = null;
    geometryEngineAsync: __esri.geometryEngineAsync = null;

    allPopupEnabled = false;

    @Watch("toRefresh")
    toRefreshChanged() {
        if (this.toRefresh) {
            this.clearSelection()
            this.$emit("refreshed")
        }
    }

    async mounted() {
        var token = (await metadataServices.getArcgisToken()).token;
        esriConfig.request.interceptors.push({
            // set the `urls` property to the URL of the FeatureLayer so that this
            // interceptor only applies to requests made to the FeatureLayer URL
            urls: [settings.map.exposurePoint,
            settings.map.sostegniLayer,
            settings.map.linesLayer, settings.map.campateLayer],
            // use the BeforeInterceptorCallback to add token to query
            before: function (params) {
                params.requestOptions.query.token = token;
            },
        });
        esriConfig.apiKey = settings.map.apiKey;
        await this.createMap();
    }

    async createMap() {
        this.map = new EsriMap({
            basemap: settings.map.basemap
        });

        this.activeView = this.mapView = new MapView({
            container: this.$refs.searchMapDiv as HTMLDivElement,
            map: this.map,
            zoom: this.zoom,
            center: this.center
        });


        await this.initializeSostegniLayer();
        await this.initializeLinesLayer();
        await this.initializeCampateLayer();
        await this.initiliazeSketchAndGraphicsLayer();
        await this.initializeExposurePointLayer();
        await this.initializeLayerList();
        await this.initializeToolbarButton();
    }

    async enableAllPopup() {
        this.allPopupEnabled = !this.allPopupEnabled;

        if (this.campateLayer)
            this.campateLayer.popupEnabled = this.allPopupEnabled;

        if (this.sostegniLayer)
            this.sostegniLayer.popupEnabled = this.allPopupEnabled;

        if (this.linesLayer)
            this.linesLayer.popupEnabled = this.allPopupEnabled;

        if (this.exposurePointLayer)
            this.exposurePointLayer.popupEnabled = this.allPopupEnabled;
    }

    async initializeToolbarButton() {
        new Search({
            view: this.activeView,
            container: "search"
        });

        const basemapGallery = new BasemapGallery({
            view: this.activeView
        });
        new Expand({
            view: this.activeView,
            content: basemapGallery,
            expanded: false,
            container: "tools"
        });

        // const selectPointButton = this.$refs["select-by-point"] as HTMLDivElement;
        // selectPointButton.addEventListener("click", () => {
        //     this.clearSelection();
        //     this.activeView.closePopup();
        //     this.sketchViewModel.create("point");
        // });

        const selectRectangleButton = this.$refs["select-by-rectangle"] as HTMLDivElement;
        selectRectangleButton.addEventListener("click", () => {
            this.clearSelection();
            this.activeView.closePopup();
            this.sketchViewModel.create("rectangle");
        });

        this.activeView.ui.add("searchToolbarDiv", "top-right")
        this.activeView.ui.move("zoom", "bottom-right");
    }

    async showHideSostegniLayer() {
        if (this.sostegniLayer) {
            this.sostegniLayer.visible = !this.sostegniLayer.visible;
        }
    }

    async showHideCampateLayer() {
        if (this.campateLayer) {
            this.campateLayer.visible = !this.campateLayer.visible;
        }
    }

    async showHideLinesLayer() {
        if (this.linesLayer) {
            this.linesLayer.visible = !this.linesLayer.visible;
        }
    }

    async initializeLayerList() {
        const layerList = new LayerList({ view: this.activeView });
        new Expand({
            view: this.activeView,
            content: layerList,
            expanded: false,
            container: "tools"
        });
    }

    async initializeLinesLayer() {
        const template = GetLineePopup();
        if (!this.activeView.map.findLayerById(this.linesLayerId)) {
            this.linesLayer = new FeatureLayer({
                id: this.linesLayerId,
                url: settings.map.linesLayer,
                minScale: 0,
                maxScale: 0,
                outFields: ["*"],
                popupEnabled: false,
                popupTemplate: template
            });
            this.activeView.map.add(this.linesLayer);
        }
    }

    async initializeCampateLayer() {
        const template = GetCampatePopup();
        if (!this.activeView.map.findLayerById(this.campateLayerId)) {
            this.campateLayer = new FeatureLayer({
                id: this.campateLayerId,
                url: settings.map.campateLayer,
                minScale: 0,
                maxScale: 0,
                outFields: ["*"],
                popupEnabled: false,
                popupTemplate: template
            });
            this.activeView.map.add(this.campateLayer);
        }
    }

    async initializeSostegniLayer() {
        const template = GetSostegniPopup();
        if (!this.activeView.map.findLayerById(this.sostegniLayerId)) {
            this.sostegniLayer = new FeatureLayer({
                id: this.sostegniLayerId,
                url: settings.map.sostegniLayer,
                minScale: 0,
                maxScale: 0,
                outFields: ["*"],
                popupEnabled: false,
                popupTemplate: template
            });
            this.activeView.map.add(this.sostegniLayer);
        }
    }

    async initializeExposurePointLayer() {
        const template = GetExposurePointPopup();
        if (!this.activeView.map.findLayerById(this.exposurePointLayerId)) {
            this.exposurePointLayer = new FeatureLayer({
                id: this.exposurePointLayerId,
                url: settings.map.exposurePoint,
                minScale: 0,
                maxScale: 0,
                outFields: ["*"],
                popupEnabled: false,
                popupTemplate: template
            });
            this.activeView.map.add(this.exposurePointLayer);
        }
    }

    async initiliazeSketchAndGraphicsLayer() {
        this.polygonGraphicsLayer = new GraphicsLayer();
        this.polygonGraphicsLayer.listMode = "hide";
        this.map.add(this.polygonGraphicsLayer);

        this.sketchViewModel = new SketchViewModel({
            view: this.activeView,
            layer: this.polygonGraphicsLayer
        });

        this.sketchViewModel.on("create", async (event) => {
            this.$emit("init_selection")
            if (event.state === "complete") {

                // this polygon will be used to query features that intersect it
                const geometries = this.polygonGraphicsLayer.graphics.map(function (graphic) {
                    return graphic.geometry;
                });

                let queryGeometry = null;
                if (event.tool === "rectangle") {
                    queryGeometry = await geometryEngineAsync.union(geometries.toArray());
                }

                if (event.tool === "point") {
                    let polygon = await geometryEngineAsync.geodesicBuffer(geometries.toArray(), 70, "meters").then(pp => {
                        queryGeometry = pp[0];
                    });
                }

                this.$emit("onSelectedGeometryChanged", queryGeometry);
                //console.log("onSelectedGeometryChanged: ", queryGeometry)
                //this.selectFeatures(queryGeometry);
            }
        });
    }

    async selectFeatures(geometry) {
        if (this.exposurePointLayer) {
            const query = {
                geometry: geometry,
                outFields: ["*"]
            };

            this.exposurePointLayer.queryFeatures(query)
                .then(async (results) => {
                    if (results.features.length === 0) {
                        await this.clearSelection();
                    }
                    this.$emit("onSelectedFeatureChanged", results.features);
                    this.$emit("selection_finished")
                })
                .catch((error) => {
                    console.log("error happened:", error.message);
                    this.$emit("selection_finished")
                });

        }
    }

    async clearSelection() {
        this.polygonGraphicsLayer.removeAll();
    }

}

