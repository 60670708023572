import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";
import { store } from "@/store";
import { metadataServices } from "./metadata.services";

class ImageServices extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => `${settings.inspectAnalyzerServiceUrl}/api/`;
    }

    async getCropImage(crop: inspect.CropModel): Promise<any> {
        let res = await this.getRaw("image/" + crop.id + "/resize", crop);
        return res;
    }

    // async getImageIssues(imageId: number): Promise<inspect.Issue[]> {
    //     let res = await this.Get<inspect.Issue[]>("metadata/image/issues", { ImageId: imageId });
    //     store.actions.images.setImageIssue({ imageId: imageId, issues: res });
    //     return res
    // }

    // async getImageAssetsCount(imageId: number): Promise<number> {
    //     let res = await this.Get<number>("metadata/image/assets/count", { ImageId: imageId });
    //     store.actions.images.setImagesAssetsCount({ imageId: imageId, assets: res });
    //     return res
    // }

    async search(search: inspect.SearchModel, page: number = 0, limit: number = 100): Promise<inspect.ImageModel[]> {
        //console.log(JSON.stringify(search))
        let images = await this.Post<inspect.ImageModel[]>(`metadata/search/${page}/${limit}`, search);
        await metadataServices.getNotes(images.map(i => i.id)) 
        for (const img of images) {
            store.actions.images.setImageIssue({ imageId: img.id, issues: img.anomalie });
            store.actions.images.setImagesAssetsCount({ imageId: img.id, assets: img.assetsCount });
        }
        store.actions.images.setList(images);
        return images;
    }
}

export const imageServices = new ImageServices();
