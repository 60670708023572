import { loginServices } from '@/services/login.service';
import type { AuthToken } from '@/services/_base/baseRestService';
import { Component, Vue } from 'vue-property-decorator';
import { CONFIGURATION } from "@/Configuration";
import QueryString from 'qs';
import { MessageService } from 'vue-mf-module';

@Component({
  components: {
  }
})
export default class Login extends Vue {
  username = '';
  password = '';
  error = '';
  selectedWorkspace: string = '';
  isSubmitting = false;
  success: AuthToken | null = null;
  activeTab: string = 'email';

  errors: { [id: string]: string; } = {};
  isPasswordClear = false;
  recoveryPassword = false;
  recoveryRequestSent = false;
  code: string = '';

  get configuration() {
    return CONFIGURATION;
  }


  get emailLoginEnabled() {
    return (this.configuration.checkLoginLocalClaim && localStorage.getItem("emailEnabled")) || this.configuration.emailLoginEnabled
  }

  mounted() {
  }

  setError(id: string, value: any) {
    this.errors[id] = value;
  }

  async signin() {
    this.isSubmitting = true;
    this.error = '';

    try {

      this.success = await loginServices.Login(this.username, this.password, this.selectedWorkspace);

      if (this.success)
        this.$emit("authenticated", this.success);

      if (this.success) {
        MessageService.Instance.send('ROUTE_TO', '/');
      }

    } catch (err: any) {
      if (err.response) {
        this.error = err.response?.data?.error_description ?? err.response.data?.detail;
      } else {
        this.error = err.message;
      }
    }
    this.isSubmitting = false;
  }


  reset() {
    loginServices.Logout();
    this.username = '';
    this.password = '';
    this.error = '';
    this.selectedWorkspace = '';
    this.isSubmitting = false;
    this.success = null;
    this.recoveryPassword = false;
  }
}
