import { store } from "@/store";
import Vue from "vue";
import Component from "vue-class-component";
import Tags from "@/components/tags/tags.vue";
import { Prop, Watch } from "vue-property-decorator";
import { ModalHelper } from "@/modals/ModalHelper";
import ImageInspect from "@/views/imageInspect/imageInspect.vue";
import Toggle from "@/components/toggle/toggle.vue";
import { GetImageThumbPath } from "@/utils/imageUtils";
import SearchTerms from "@/components/searchTerms/searchTerms.vue";
import { metadataServices } from "@/services/metadata.services";
import { imageServices } from "@/services/image.services";
import loadMore from "@/components/loadMore/loadMore.vue";

@Component({
    components: {
        Tags,
        Toggle,
        SearchTerms,
        loadMore
    }
})
export default class ImageSearch extends Vue {

    @Prop()
    mission: string

    @Prop({ default: [] })
    objectsSelectedIds: number[]

    @Watch("objectsSelectedIds")
    objectsSelectedIdsChanged() {
        if (this.objectsSelectedIds && this.objectsSelectedIds.length > 0) {
            let element = document.getElementById(this.objectsSelectedIds[0].toString())
            if (element) {
                let container = document.getElementById('container')
                container.scrollTop = element.offsetTop - container.offsetTop;
            }
        }
    }

    searching = false;
    image_ids: number[] = []
    isOpen: boolean = true
    confrontMode: boolean = false
    imagesToConfront: inspect.ImageModel[] = []
    view_only_with_anomalies: boolean = false
    view_only_not_inspected: boolean = false
    components_selected: inspect.Component[] = []
    anomalies_selected: inspect.IssueType[] = []
    imageIds_selected: number[] = []

    mounted() {
        let mapHomeSearch = store.getters.search.getMapHomeSearch()
        if (mapHomeSearch) {
            this.components_selected = mapHomeSearch.components_selected
            this.anomalies_selected = mapHomeSearch.anomalies_selected
            this.imageIds_selected = mapHomeSearch.imageIds_selected
            this.view_only_not_inspected = mapHomeSearch.view_only_not_inspected
            this.view_only_with_anomalies = mapHomeSearch.view_only_with_anomalies
        }
        this.imageIds = this.componentsSelected.length > 0 || this.anomaliesSelected.length > 0 ?
            this.filteredImagesList.map(fi => fi.id).sort((n1, n2) => n1 - n2) : this.imagesList.map(img => img.id).sort((n1, n2) => n1 - n2)
    }

    beforeDestroy() {
        store.actions.search.setMapHomeSearch({
            anomalies_selected: this.anomaliesSelected,
            components_selected: this.componentsSelected,
            imageIds_selected: this.imageIdsSelected,
            view_only_not_inspected: this.viewOnlyNotInspected,
            view_only_with_anomalies: this.viewOnlyWithAnomalies
        })
    }

    get viewOnlyWithAnomalies() {
        return this.view_only_with_anomalies
    }
    set viewOnlyWithAnomalies(value) {
        store.actions.search.setAnalyticsData(null)
        this.view_only_with_anomalies = value
    }

    get viewOnlyNotInspected() {
        return this.view_only_not_inspected
    }
    set viewOnlyNotInspected(value) {
        store.actions.search.setAnalyticsData(null)
        this.view_only_not_inspected = value
    }

    get imageIdsSelected() {
        return this.imageIds_selected
    }
    set imageIdsSelected(value) {
        store.actions.search.setAnalyticsData(null)
        this.imageIds_selected = value
    }

    get componentsSelected() {
        return this.components_selected
    }
    set componentsSelected(value) {
        store.actions.search.setAnalyticsData(null)
        this.anomaliesSelected = []
        this.imageIdsSelected = []
        this.components_selected = value
        if (value.length > 0 || this.anomaliesSelected.length > 0) {
            this.imageIds = this.filteredImagesList.map(fi => fi.id).sort((n1, n2) => n1 - n2)
        }
        else this.imageIds = this.imagesList.map(img => img.id).sort((n1, n2) => n1 - n2)
    }

    get anomaliesSelected() {
        return this.anomalies_selected
    }
    set anomaliesSelected(value) {
        store.actions.search.setAnalyticsData(null)
        this.imageIdsSelected = []
        this.anomalies_selected = value
        if (value.length > 0 || this.componentsSelected.length > 0) {
            this.imageIds = this.filteredImagesList.map(fi => fi.id).sort((n1, n2) => n1 - n2)
        }
        else this.imageIds = this.imagesList.map(img => img.id).sort((n1, n2) => n1 - n2)
    }

    get filteredImagesList() {
        this.imagesToConfront = [];
        this.confrontMode = false;
        let list = this.imageIdsSelected.length > 0 ? this.imagesList.filter(img => this.imageIdsSelected.includes(img.id)) : this.imagesList
        if (this.terms && this.terms.issue.length > 0)
            list = list.filter(img =>
                store.getters.images.getImageIssue(img.id).filter(issue => this.anomalies.map(anomaly => anomaly.anomalia).includes(issue.name)).length > 0
            )
        if (this.anomaliesSelected.length > 0)
            list = list.filter(img =>
                store.getters.images.getImageIssue(img.id).filter(issue => this.anomaliesSelected.map(anomaly => anomaly.anomalia).includes(issue.name)).length > 0
            )
        if (this.viewOnlyWithAnomalies)
            list = list.filter(img => store.getters.images.getImageIssue(img.id).length > 0)
        this.$emit("filteredImagesListChanged", list);
        return list
    }

    get showMission(): boolean {
        return store.getters.search.getShowMission()
    }

    get imagesList() {
        return this.showMission ? store.getters.images.getMission(this.mission).images : store.getters.images.getImageList();
    }

    get imageIds() {
        return this.image_ids
    }
    set imageIds(value) {
        this.image_ids = value
    }

    get terms() {
        return store.getters.search.getCurrentSearchData()
    }

    get components() {
        let components_terms = this.terms != null && this.terms != undefined ?
            this.terms.issue.map(issue => issue.idComponente) : []
        return store.getters.domain.getComponents().filter(i => components_terms.includes(i.id))
    }

    get anomalies() {
        let issue_terms = this.terms != null && this.terms != undefined ?
            this.terms.issue.map(issue => issue.anomalia) : []
        let issues = store.getters.domain.getIssues().filter(i => issue_terms.includes(i.anomalia))
        let component_ids_selected = this.componentsSelected.map(c => c.id)
        return this.componentsSelected.length > 0 ? issues.filter(i => component_ids_selected.includes(i.idComponente)) : issues
    }

    get secondId(): number {
        return this.imagesToConfront.length > 1 ?
            this.imagesToConfront[1].id : 0
    }

    get firstId(): number {
        return this.imagesToConfront.length > 0 ?
            this.imagesToConfront[0].id : 0
    }

    get searchPage() {
        return store.getters.search.getSearchPage()
    }
    set searchPage(page: number) {
        store.actions.search.setSearchPage(page)
    }

    imageDetail(i: inspect.ImageModel) {
        if (i) {
            return JSON.parse(i.json);
        }
        return {};
    }

    addElement(image: inspect.ImageModel) {
        var index = this.imagesToConfront.findIndex(img => img.id == image.id)
        if (index != -1) {
            this.imagesToConfront.splice(index, 1)
        } else {
            if (this.imagesToConfront.length == 2) {
                this.imagesToConfront.splice(0, 1)
            }
            this.imagesToConfront = [...this.imagesToConfront, image]
        }
    }

    highlight(imageId: string) {
        this.$emit("highlight", imageId)
    }

    resetSearch() {
        this.viewOnlyWithAnomalies = false
        this.viewOnlyNotInspected = false
        this.componentsSelected = []
        this.anomaliesSelected = []
        this.imageIdsSelected = []
    }

    selectedItem(image: inspect.ImageModel) {
        if (image && image.id && !this.confrontMode) {

            let imageNote = store.getters.images.getImageNote(image.id)
            let note = imageNote != undefined && imageNote != null ?
                JSON.parse(JSON.stringify(imageNote)) :
                {
                    imageId: image.id,
                    erroreAnomalia: false,
                    nuovaAnomalia: false,
                    note: ""
                }

            ModalHelper.push({
                title: `Dettagli immagine`,
                content: ImageInspect,
                id: "image_inspect",
                closeButton: true,
                hideFooter: false,
                model: {
                    images: this.filteredImagesList,
                    imageId: image.id,
                    note: note
                },
                buttons: [
                    { class: "second", id: "back_to_research", title: "Torna alla ricerca" },
                    { class: "second", id: "back_to_map", title: "Torna alla mappa" },
                    { id: "confirm", title: "Invia note" }
                ],
                callback: async (id, mod) => {
                    if (id === "confirm") {
                        await metadataServices.saveNote(mod.note)
                    }
                    if (id === "back_to_research") {
                        this.$router.push({ name: "home" })
                    }
                    return true;
                }
            })
        }
    }

    confront() {

        let imageNote = store.getters.images.getImageNote(this.imagesToConfront[0].id)
        let note = imageNote != undefined && imageNote != null ?
            JSON.parse(JSON.stringify(imageNote)) :
            {
                imageId: this.imagesToConfront[0].id,
                erroreAnomalia: false,
                nuovaAnomalia: false,
                note: ""
            }

        ModalHelper.push({
            title: `Confronto dettagli immagini`,
            content: ImageInspect,
            id: "image_inspect",
            closeButton: true,
            hideFooter: false,
            model: {
                images: this.imagesToConfront,
                note: note
            },
            buttons: [
                { class: "second", id: "back_to_research", title: "Torna alla ricerca" },
                { class: "second", id: "back_to_map", title: "Torna alla mappa" },
                { id: "confirm", title: "Invia note" }
            ],
            callback: async (id, mod) => {
                if (id === "confirm") {
                    await metadataServices.saveNote(mod.note)
                }
                if (id === "back_to_research") {
                    this.$router.push({ name: "home" })
                }
                return true;
            }
        })
    }

    getThumbPath(id: number) {
        return GetImageThumbPath(id);
    }

    async loadMore() {
        if (this.searchPage >= 0 && this.imageIdsSelected.length == 0 && !this.searching){
            this.searching = true
            this.searchPage = this.searchPage + 1
            await imageServices.search(store.getters.search.getCurrentSearchData(), this.searchPage)
            this.imageIds = this.filteredImagesList.map(fi => fi.id).sort((n1, n2) => n1 - n2)
            this.searching = false
        }
    }

}