import { store } from '@/store';
import Vue from 'vue'
import Component from 'vue-class-component';
import Tags from "@/components/tags/tags.vue";

@Component({
    components: {
        Tags
    }
})
export default class SearchTerms extends Vue {

    emptyResearch: inspect.SearchModel = {
        ids: [],
        asset: null,
        extent:null,
        issue: [],
        line: null,
        fromDate: null,
        toDate: null,
        elaborationFromDate:null,
        elaborationToDate:null,
        showProcessed: false,
        showNotProcessed: false,
        operator: []
    };

    deepEqual = require("deep-equal")

    get terms() {
        return store.getters.search.getCurrentSearchData()
    }

    get searchTerms() {
        if (this.terms != null && this.terms != undefined && !this.deepEqual(this.terms, this.emptyResearch))
            return {
                line: this.terms.line != null && this.terms.line.pltxt != "" ? [this.terms.line.strno + " - " + this.terms.line.pltxt] : [],
                
                asset: this.terms.asset != null && this.terms.asset != null ? [this.terms.asset.asset] : [],
                
                elaborationDate: this.terms.elaborationFromDate != null || this.terms.elaborationToDate != null ? 
                [`data elaborazione ${this.formatDate(this.terms.elaborationFromDate, this.terms.elaborationToDate)}`] : [],
                
                date: this.terms.fromDate != null || this.terms.toDate != null ? 
                [`data scatto ${this.formatDate(this.terms.fromDate, this.terms.toDate)}`] : [],
                
                districts: this.terms.extent != null ? 
                [`${this.terms.extent.latmin.toFixed(4)} <= Lat <= ${this.terms.extent.latmax.toFixed(4)}` , 
                `${this.terms.extent.longmin.toFixed(4)} <= Long <= ${this.terms.extent.longmax.toFixed(4)}`] : [],
                
                issues: this.terms.issue.length > 0 ? this.terms.issue.map(i => i.anomalia) : [],
            }
        return null;
    }

    formatDate(from: Date, to: Date) {
        let formattedDate: string = "-"
        let toDate = new Date(to).toLocaleString("IT-it").split(',')[0]
        let fromDate = new Date(from).toLocaleString("IT-it").split(',')[0]

        if (from != null) to != null ?
            formattedDate = `${fromDate} - ${toDate}` :
            formattedDate = `${fromDate} - ${toDate}`
        else if (from != null)
            formattedDate = `${toDate} - ${toDate}`

        return formattedDate
    }

}