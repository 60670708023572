import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class Toggle extends Vue {

    @Prop()
    label: string

    @Prop()
    value: boolean

    @Prop()
    id: string

    public get toggleValue(): boolean {
        return this.value;
    }
    public set toggleValue(v: boolean) {
        this.$emit("input", v)
    }
}
