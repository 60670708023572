export const CONFIGURATION = {
  connectServiceUri: "",
  securityServiceUri: "https://api.prod.wise.town/identity",
  // AccountServiceUri: "https://api.prod.wise.town/account",
  auth: {
    RefreshTokenInterval: 1,
    Scope: "",
    ClientId: "",
    LocalRegistrationEnabled: true,
  },
  colors: ['#f44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#FFC107', '#FF9800', '#FF5722'],
  backgroundImage: null,
  cobrandingImage: null,
  logoImage: null,
  welcomeText: "",
  limitRolesTo: [],
  emailLoginEnabled: true,
  resetPasswordEnabled: false,
  checkLoginLocalClaim: true,
  externalProviders: [

  ]
};
