import { Vue, Component, Prop } from "vue-property-decorator";
import { store } from "@/store";
import DrawCanvas from "@/components/drawcanvas/drawCanvas.vue";
import DisplayLabel from "@/components/displaylabel/displayLabel.vue";
import { GetImageResize } from "@/utils/imageUtils";
import { Watch } from 'vue-property-decorator';
import settings from "@/settings";

@Component({
    components: {
        DrawCanvas,
        DisplayLabel
    },
})
export default class ImageViewer extends Vue {

    @Prop()
    currentImage: inspect.ImageModel;

    @Prop({ default: 100 }) // valore in percentuale
    brightness: number;

    @Prop({ default: 100 }) // valore in percentuale
    contrast: number;

    @Prop({ default: 100 }) // valore in percentuale
    sharpness: number;

    @Prop()
    highligthedIssue: inspect.Issue

    isZoomSelectionEnabled = false;
    imageKey: number = 0;
    lastCroppedImage: any = null;
    lastSelectedArea: inspect.SelectedArea = null;
    imageViewHeight: number = 0;
    imageViewWidth: number = 0;
    zoomCounter: number = 0;
    zoomTopLeft: number = 50;
    isImageLoaded: boolean = false;
    showIssues: boolean = true;
    disableLabelIssueClick: boolean = false;
    //Altezza o larghezza tanto controllo se una è maggiore dell'altra
    minZoomAdded = 22;

    cropHystory: inspect.SelectedArea[] = [];

    get labelsBoxTop() {
        if (this.lastSelectedArea == null) return null
        return (this.lastSelectedArea.top / this.imageViewHeight) * settings.serverImageHeight
    }

    get labelsBoxLeft() {
        if (this.lastSelectedArea == null) return null
        return (this.lastSelectedArea.left / this.imageViewWidth) * settings.serverImageWidth
    }

    get labelsBoxMaxWidth() {
        if (this.lastSelectedArea == null) return settings.serverImageWidth
        return this.lastSelectedArea.width / (this.imageViewWidth / settings.serverImageWidth)
    }

    get labelsBoxMaxHeight() {
        if (this.lastSelectedArea == null) return settings.serverImageHeight
        return this.labelsBoxMaxWidth * (settings.serverImageHeight / settings.serverImageWidth)
    }

    get labels() {
        return store.getters.images.getImageIssue(this.currentImage.id)
    }

    mounted() {

        let _realPhotoFactor = settings.serverImageWidth / settings.serverImageHeight;

        this.isImageLoaded = false;
        this.imageViewWidth = (this.$refs.imgcontainerview as HTMLDivElement).clientWidth;
        this.imageViewHeight = (this.$refs.imgcontainerview as HTMLDivElement).clientHeight;
        this.getImage();
    }

    @Watch("currentImage")
    currentImageChanged(n, o) {
        if (n != o) {
            this.getImage();
        }
    }

    @Watch("lastSelectedArea")
    onSelectedAreaChanged(n, o) {
        if (n != o) {
            this.getImage();
        }
    }

    getImage() {
        this.isImageLoaded = false;
        if ((this.cropHystory && this.cropHystory.length > 0) || this.lastSelectedArea) {
            this.lastCroppedImage = GetImageResize(this.currentImage.id,
                this.imageViewWidth,
                this.imageViewHeight,
                this.lastSelectedArea.top,
                this.lastSelectedArea.left,
                this.lastSelectedArea.width,
                this.lastSelectedArea.height,
                true
            );
        } else {
            this.lastCroppedImage = GetImageResize(this.currentImage.id, this.imageViewWidth, this.imageViewHeight);
        }

        return this.lastCroppedImage;
    }

    private refreshImage() {
        this.imageKey = this.imageKey + 1;
    }

    async onSelectedArea(area: inspect.SelectedArea) {
        console.log("MANUALSELECTION:", area);

        //Controlli che la selezione non vada oltre l'area
        // if(area.top + area.height >= this.imageViewHeight){
        //     area.top  = this.imageViewHeight -  area.height;
        // }

        // if(area.left + area.width >= this.imageViewWidth){
        //     area.left  = this.imageViewWidth -  area.width;
        // }

        this.internalOnSelectArea(area);
    }

    private internalOnSelectArea(area: inspect.SelectedArea) {

        if (this.cropHystory && this.cropHystory.length > 0) {
            let lastElement = this.cropHystory[this.cropHystory.length - 1];
            this.lastSelectedArea = this.scaleAndCrop(area, lastElement, false);
        } else {
            this.lastSelectedArea = area;
        }

        this.cropHystory.push(this.lastSelectedArea);
    }

    private scaleAndCrop(currentSelectedArea, lastElement, log: boolean = false): any {

        let scaledTop = (currentSelectedArea.top * lastElement.height) / this.imageViewHeight;
        let scaledLeft = (currentSelectedArea.left * lastElement.width) / this.imageViewWidth;

        if (scaledTop <= 0) scaledTop = 0;
        if (scaledLeft <= 0) scaledLeft = 0;

        let finalTop = lastElement.top + scaledTop;
        let finalLeft = lastElement.left + scaledLeft;

        if (log) {
            console.log("---> selectedTop: " + currentSelectedArea.top + " ,selectedLeft: " + currentSelectedArea.left + " ,scaledTop: " + scaledTop + " ,scaledLeft: " + scaledLeft + " ,finalTop: " + finalTop + " ,finalLeft: " + finalLeft);
        }

        let scaledWidth = (currentSelectedArea.width * lastElement.width) / this.imageViewWidth;
        let scaledHeight = ((currentSelectedArea.width / 1.333) * lastElement.height) / this.imageViewHeight;

        if (scaledWidth <= 0) scaledWidth = 0;
        if (scaledHeight <= 0) scaledHeight = 0;

        let finalWidth = scaledWidth;
        let finalHeight = scaledHeight;

        if (log) {
            console.log("scaledWidth: ", scaledWidth);
            console.log("scaledHeight: ", scaledHeight);
            console.log("finalWidth: ", finalWidth);
            console.log("finalHeight: ", finalHeight);
        }


        return {
            top: finalTop,
            left: finalLeft,
            width: finalWidth,
            height: finalHeight,
        } as inspect.SelectedArea;

    }

    async zoomReset() {
        this.showIssues = true;
        this.cropHystory = [];
        this.lastSelectedArea = null;
        this.zoomCounter = 0;
        this.disableLabelIssueClick = false;
        this.refreshImage();
    }

    async zoomSelection() {
        this.isZoomSelectionEnabled = !this.isZoomSelectionEnabled;
    }

    async zoomIn() {
        if (this.zoomCounter >= 0) {
            this.zoomCounter++;

            const left = 140;
            const top = left / 1.333;

            let fixedZomm = {
                top: top,
                left: left,
                width: this.imageViewWidth - (left * 2),
                height: this.imageViewHeight - (top * 2),
            };

            this.internalOnSelectArea(fixedZomm);
            this.refreshImage();
        }
    }

    async zoomOut() {

        if (this.zoomCounter > 1 && this.cropHystory.length > 1) {
            this.zoomCounter--;
            this.cropHystory.pop()
            this.lastSelectedArea = this.cropHystory.pop();
            this.refreshImage();
        } else {
            this.zoomReset();
        }

    }

    private scaleArea(area) {

        const _left = (area.left * this.imageViewWidth) / settings.serverImageWidth;
        const _top = (area.top * this.imageViewHeight) / settings.serverImageHeight;
        const _width = (area.width * this.imageViewWidth) / settings.serverImageWidth;
        const _height = (area.height * this.imageViewHeight) / settings.serverImageHeight;

        return {
            left: _left,
            top: _top,
            width: _width,
            height: _height,
        };
    }

    async scaleAndZommToIssue(issue) {
        if (!this.showIssues) {
            this.zoomReset();
        }
        //this.showIssues = false;
        return this.zoomToIssue(this.scaleArea(issue));
    }

    //HO LE DIMENSIONI RELATIVE, QUELLE CHE VEDO
    async zoomToIssue(issue) {

        //SE LE ANOMALIE SONO MOSTRATE = SE I RETTANGOLI SI VEDONO = SE NON HO GIA' ZOOMMATO AL MASSIMO UN ANOMALIA
        if (!this.showIssues) {
            this.zoomReset();
        }

        this.disableLabelIssueClick = true;

        let _factor = this.imageViewWidth / (this.imageViewHeight);
        console.log("FACTOR:", _factor);
        //Inizializzo le variabili al minZoom impostato
        //this.showIssues = false;
        let _newZoomWidth = this.minZoomAdded;
        let _newZoomHeight = this.minZoomAdded / _factor;
        let _newZoomLeft = 0;
        let _newZoomTop = 0;

        //SE E' maggiore l'altezza prendo l'altezza come minimo && issue.height > this.minZoom
        if (issue.height >= issue.width) {
            _newZoomHeight = issue.height + this.minZoomAdded;
            _newZoomWidth = _newZoomHeight * _factor;
        }

        //SE E' maggiore la larghezza prendo la larghezza come minimo && issue.width > this.minZoom
        if (issue.width >= issue.height) {
            _newZoomWidth = issue.width + (this.minZoomAdded *2);
            _newZoomHeight = _newZoomWidth  / _factor;
        }

        let _widthDistanceFromCenter = (_newZoomWidth / 2 - issue.width / 2);
        let _heightDistanceFromCenter = (_newZoomHeight / 2 - issue.height / 2)

        _newZoomLeft = issue.left - _widthDistanceFromCenter;
        _newZoomTop = issue.top - _heightDistanceFromCenter;

        //CONTROLLI SUI BORDI
        //LEFT
        //Issue tutta a sinistra
        //Se il left è negativo lo porto a zero e aggiungo l'assoluto del left alla width
        if (_newZoomLeft < 0) {
            _newZoomWidth = _newZoomWidth + Math.abs(_newZoomLeft);
            _newZoomLeft = 0;
        }
        //Issue tutta a destra
        //se il left + la width è maggiore dell'area metto left come width totale - width issue
        if (_newZoomLeft + _newZoomWidth > this.imageViewWidth) {
            _newZoomLeft = this.imageViewWidth - _newZoomWidth;
        }

        //TOP
        //Issue troppo in alto
        if (_newZoomTop <= 0) {
            _newZoomHeight = _newZoomHeight + Math.abs(_newZoomTop);
            _newZoomTop = 0;
        }

        //Issue troppo in basso
        if (_newZoomTop + _newZoomHeight > this.imageViewHeight) {
            _newZoomTop = this.imageViewHeight - _newZoomHeight;
        }

        //AREA MINIMA DI ZOOM FISSO RELATIVA: larghezza 72, altezza proporizionata alla larghezza, top e left le calcolo dopo
        let minimumArea = { left: _newZoomLeft, top: _newZoomTop, width: _newZoomWidth, height: _newZoomHeight }

        console.log("AREA ORIGINALE:", issue)
        console.log("NUOVA AREA:", minimumArea);

        return this.internalOnSelectArea(minimumArea);
    }

    async loadedImage() {
        this.isImageLoaded = true;
    }
}


