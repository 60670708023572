import { Vue, Component } from "vue-property-decorator";
import { Prop, Watch } from "vue-property-decorator";
import Tags from "../tags/tags.vue";
import { GetImageThumbPath } from "@/utils/imageUtils";
import { store } from "@/store";

@Component({
  components: {
    Tags
  },
})
export default class Card extends Vue {

  @Prop({ required: true })
  model: inspect.Mission;

  date: string = null;
  elaborationDate: string = null;

  get tagsList() {
    let missionIssues: string[] = []
    this.model.images.forEach(i => {
      store.getters.images.getImageIssue(i.id).forEach(i => missionIssues.push(i.name))
    })
    return missionIssues
  }

  get assetsCount() {
    let missionAssets: number = 0
    this.model.images.forEach(i => {
      missionAssets += store.getters.images.getImageAssetsCount(i.id)
    })
    return missionAssets
  }

  mounted() {
    this.date = this.model.images[0].dataScatto.toLocaleDateString("IT-it")
    this.elaborationDate = this.model.images[0].dataAcquisizione.toLocaleDateString("IT-it")
  }

  async inspectItem() {
    this.$router.push({ name: "map_home", params: { mission: this.model.missione } })
  }
}