import { Vue, Component } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";
import Tags from "../tags/tags.vue";
import { ModalHelper } from "@/modals/ModalHelper";
import ImageInspect from "@/views/imageInspect/imageInspect.vue";
import { GetImageThumbPath } from "@/utils/imageUtils";
import { metadataServices } from "@/services/metadata.services";
import { store } from "@/store";

@Component({
  components: {
    Tags,
  },
})
export default class Card extends Vue {

  @Prop({ required: true })
  model: inspect.ImageModel;

  @Prop({ default: [], required: false })
  images: inspect.ImageModel[];

  date: string = null;
  elaborationDate: string = null;
  position: string = null;

  get tagsList() {
    return store.getters.images.getImageIssue(this.model.id)
  }

  get getTagList(){
    return this.tagsList.map(i => i.name);
  }

  get assetsCount() {
    return store.getters.images.getImageAssetsCount(this.model.id) ?? "-"
  }

  get operatorsList() {
    return [
      {
        id: 1,
        value: "Alessio",
        imageUrl: "@/assets/user.png",
      },
      {
        id: 2,
        value: "Marco",
        imageUrl: "@/assets/user.png",
      },
      {
        id: 3,
        value: "Alessandro",
        imageUrl: "@/assets/user.png",
      },
    ]
  }

  async mounted() {
    this.date = this.model.dataScatto.toLocaleDateString("IT-it");
    this.elaborationDate = this.model.dataAcquisizione.toLocaleDateString("IT-it");
    this.position = `Lat ${this.model.latitude.toFixed(2)}, Lon ${this.model.longitude.toFixed(2)}, Alt ${this.model.altitude.toFixed(2)}`
  }

  async inspectItem() {
    // this.$router.push({ name: "map_home", params: { mission: this.model.missione, image: JSON.stringify(this.model) } })
    if (this.model != null) {
      let image: inspect.ImageModel = Object.assign({},this.model);

      let imageNote = store.getters.images.getImageNote(image.id)
      let note = imageNote != undefined && imageNote != null ?
          JSON.parse(JSON.stringify(imageNote)) :
          {
              imageId: image.id,
              erroreAnomalia: false,
              nuovaAnomalia: false,
              note: ""
          }


      ModalHelper.push({
          title: `Dettagli immagine`,
          content: ImageInspect,
          id: "image_inspect",
          closeButton: true,
          hideFooter: false,
          model: {
              images: this.images.length > 0 ? this.images : [image],
              imageId: image.id,
              note: note
          },
          buttons: [
              { class: "second", id: "back_to_research", title: "Torna alla ricerca" },
              { class: "second", id: "back_to_map", title: "Torna alla mappa" },
              { id: "confirm", title: "Invia note" }
          ],
          callback: async (id, mod) => {
              if (id === "confirm") {
                  await metadataServices.saveNote(mod.note)
              }
              if (id === "back_to_research") {
                  this.$router.push({ name: "home" })
              }
              return true;
          }
      })
  }
  }

  getThumbPath(id: number) {
    return GetImageThumbPath(id);
  }

}
