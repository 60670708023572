import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class Tags extends Vue {
  
  @Prop({ default: () => [] })
  tagsList: string[];

  @Prop()
  max: number;

  @Prop({ default: false })
  vertical: boolean;

  @Prop({ default: true })
  tooltipUnderneath: boolean;

  tag: string = null;
  groupedTag: number = null;

  get sameTags() {
    var singleTag: { tag: string; num: number }[] = [];
    this.tagsList.forEach(tl => {
      var index = singleTag.findIndex((st) => st.tag == tl);
      if (index == -1) singleTag.push({ tag: tl, num: 1 });
      else singleTag[index].num++;
    });
    return singleTag;
  }

  get moreTags() {
    if (this.sameTags.length > this.max) return this.sameTags.length - this.max;
    return 0;
  }
}
