export interface SearchStoreModel {
    storedSearchList: inspect.DeserializedStoredSearch[];
    currentSearch: inspect.DeserializedStoredSearch;
    showMission: boolean;
    mapHomeSearch: inspect.MapHomeSearch;
    analyticsData: inspect.AnalyticsData;
    searchPage: number
}

export interface SearchStoreActions {
    setCurrentSearch(model: inspect.DeserializedStoredSearch);
    setSearchList(model: inspect.DeserializedStoredSearch[]);
    addToSearchList(model: inspect.DeserializedStoredSearch)
    deleteFromSearchList(model: inspect.DeserializedStoredSearch)
    setShowMission(model: boolean);
    setMapHomeSearch(model: inspect.MapHomeSearch);
    setAnalyticsData(model: inspect.AnalyticsData)
    setSearchPage(model: number)
}

export interface SearchStoreGetters {
    getSearchList(): inspect.DeserializedStoredSearch[];
    getCurrentSearchData(): inspect.SearchModel;
    getCurrentSearch(): inspect.DeserializedStoredSearch;
    getShowMission(): boolean;
    getMapHomeSearch(): inspect.MapHomeSearch;
    getAnalyticsData(): inspect.AnalyticsData
    getSearchPage(): number
}

export const SearchStore = {
    PREFIX: "search",
    namespaced: true,
    state: {
        storedSearchList: [],
        currentSearch: null,
        showMission: false,
        mapHomeSearch: null,
        analyticsData: null,
        searchPage: -1
    },
    actions: {
        setSearchPage(context, model: number) {
            context.commit("setSearchPage", model)
        },
        setCurrentSearch(context, model: inspect.DeserializedStoredSearch) {
            context.commit("setCurrentSearch", model)
        },
        setSearchList(context, model: inspect.DeserializedStoredSearch[]) {
            context.commit("setSearchList", model)
        },
        addToSearchList(context, model: inspect.DeserializedStoredSearch) {
            context.commit("addToSearchList", model)
        },
        deleteFromSearchList(context, model: inspect.DeserializedStoredSearch) {
            context.commit("deleteFromSearchList", model)
        },
        setShowMission(context, model: boolean) {
            context.commit("setShowMission", model)
        },
        setMapHomeSearch(context, model: inspect.MapHomeSearch) {
            context.commit("setMapHomeSearch", model)
        },
        setAnalyticsData(context, model: inspect.AnalyticsData) {
            context.commit("setAnalyticsData", model)
        },
    },
    mutations: {
        setSearchPage(state: SearchStoreModel, model: number) {
            state.searchPage = model
        },
        setSearchList(state: SearchStoreModel, model: inspect.DeserializedStoredSearch[]) {
            state.storedSearchList = model
        },
        addToSearchList(state: SearchStoreModel, model: inspect.DeserializedStoredSearch) {
            var index = state.storedSearchList.findIndex(s => s.id == model.id)
            if (index == -1) {
                state.storedSearchList.push(model);
            } else {
                state.storedSearchList[index] = model
            }
        },
        deleteFromSearchList(state: SearchStoreModel, model: inspect.DeserializedStoredSearch) {
            var index = state.storedSearchList.findIndex(s => s.id == model.id)
            state.storedSearchList.splice(index, 1)
        },
        setCurrentSearch(state: SearchStoreModel, model: inspect.DeserializedStoredSearch) {
            state.currentSearch = model;
        },
        setShowMission(state: SearchStoreModel, model: boolean) {
            state.showMission = model;
        },
        setMapHomeSearch(state: SearchStoreModel, model: inspect.MapHomeSearch) {
            state.mapHomeSearch = model;
        },
        setAnalyticsData(state: SearchStoreModel, model: inspect.AnalyticsData) {
            state.analyticsData = model;
        },
    },
    getters: {
        getSearchPage: state => (): number => state.searchPage,
        getSearchList: state => (): inspect.DeserializedStoredSearch[] => state.storedSearchList,
        getCurrentSearchData: state => (): inspect.SearchModel => state.currentSearch?.jsonData,
        getCurrentSearch: state => (): inspect.DeserializedStoredSearch => state.currentSearch,
        getShowMission: state => (): boolean => state.showMission,
        getMapHomeSearch: state => (): inspect.MapHomeSearch => state.mapHomeSearch,
        getAnalyticsData: state => (): inspect.AnalyticsData => state.analyticsData,
    }
}